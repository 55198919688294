.category .owl-dots .owl-dot.active {
  width: 6px !important;
  background-color: #a84198 !important;
}
.category .owl-item {
  overflow: visible !important;
}

.category .owl-carousel .owl-stage,
.che-slider .owl-carousel .owl-stage,
.recommand-slider .owl-carousel .owl-stage {
  padding-left: 0 !important;
}
.owl-carousel .owl-stage {
  display: flex;
  flex-wrap: wrap;
}
.category .owl-carousel .owl-item {
  overflow: hidden;
  border-radius: 5px;
}

.category .owl-dots .owl-dot {
  width: 6px;
  height: 6px;
  background: #e6e6e5;
  border-radius: 8px;
  margin: 0 2px;
  overflow: hidden;
  cursor: pointer;
  transition: width 0.3s ease;
}
.category .owl-dots {
  display: flex;
  justify-content: center;
  margin: 10px 0 0;
}
.category .owl-dots .owl-dot.active {
  width: 19px;
  background: #009a46;
}
.category .owl-carousel .owl-item {
  overflow: hidden;
  border-radius: 5px;
}
