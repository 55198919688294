@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scroll {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
